import BandwagonDotCom from "../components/BandwagonDotCom"
import HeroBar from '../components/HeroBar'
import MobileMenu from "../components/MobileMenu"
import React from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import './team.scss';

const headshotAttrs = {
    className: "headshot",
    objectFit: "contain"
};

const Team = () => <BandwagonDotCom className="team-list-page">
  <MobileMenu>
    <Link to="/team">Team</Link>
    <Link className="hiring" to="https://bandwagon.breezy.hr/">We're&nbsp;Hiring!</Link>
    <Link to="/">Home</Link>
    <Link to="/contact">Contact</Link>
  </MobileMenu>
  <HeroBar />
  <div class="team-list-content">
      <h1>Meet The Team</h1>
      <div id="team-grid">
        <article>
            <StaticImage src="../images/harold-hughes-headshot.jpg"
                    alt="Harold Hughes" {...headshotAttrs} />
            <h1>Harold Hughes</h1>
            <h2>Chief Executive Officer</h2>
            <hr/>
            <Link to="/team/harold-hughes">Read Bio</Link>
        </article>
        <article>
            <StaticImage src="../images/hampton-smith-headshot.jpg"
                    alt="Hampton Smith" {...headshotAttrs} />
            <h1>Hampton Smith</h1>
            <h2>Chief Technology Officer</h2>
            <hr/>
            <Link to="/team/hampton-smith">Read Bio</Link>
        </article>
        <article>
            <StaticImage src="../images/shondra-washington-headshot.png"
                    alt="Shondra Washington" {...headshotAttrs} />
            <h1>Shondra Washington</h1>
            <h2>Chief Financial Officer</h2>
            <hr/>
            <Link to="/team/shondra-washington">Read Bio</Link>
        </article>
        <article>
            <StaticImage src="../images/monet-rouse-headshot.png"
                    alt="Monét Rouse"
                    className="headshot"
                    objectFit="contain" {...headshotAttrs} />
            <h1>Monét Rouse</h1>
            <h2>Head of Product</h2>
            <hr/>
            <Link to="/team/monet-rouse">Read Bio</Link>
        </article>
        <article>
            <StaticImage src="../images/seth-russell-headshot.png"
                    alt="Seth Russell" {...headshotAttrs} />
            <h1>Seth Russell</h1>
            <h2>Software Engineer</h2>
            <hr/>
            <Link to="/team/seth-russell">Read Bio</Link>
        </article>
      </div>
  </div>
</BandwagonDotCom>;

export default Team
